.inner_wp_content {
  @apply font-basis-grotesque-pro-regular;
  & > h1,
  h3 {
    @apply my-2 text-[1.7rem] leading-tight tracking-tighter text-gray-900;
  }
  & > audio {
    @apply rounded-full;
  }
  & > p {
    @apply mt-2 text-base/6 text-pretty text-gray-700 lg:max-w-3xl;
  }
}
